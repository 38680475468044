import { BehaviorSubject, interval, Subject, take } from 'rxjs';
import { PlayersPositions } from '../data/GameProperties';
import { GameDataHandler } from '../helpers/GameDataHandler';
import { EnumLayouts } from '../helpers/constants/EnumLayouts';
import { EnumGameModes } from '../helpers/constants/GameModes';

export class Model {
  constructor() {
    this.gdh = new GameDataHandler();

    /**
     * @type {BehaviorSubject<'start_mode' | 'start'>}
     */
    this.startScene$ = new BehaviorSubject(EnumLayouts.START_MODE);

    /**
     * @type {BehaviorSubject<'single' | 'multi'>}
     */
    this.mode$ = new BehaviorSubject(
      [EnumGameModes.SINGLE, EnumGameModes.MULTI].includes(process.env.MODE)
        ? process.env.MODE
        : EnumGameModes.SINGLE
    );

    /**
     * @type {BehaviorSubject<boolean | 'skip'>}
     */
    this.activeMatchMaking$ = new BehaviorSubject(false);

    this.selectedMode$ = new BehaviorSubject('classic');
    this.selectedColor$ = new Subject();
    this.currentPlayerColor$ = new Subject();
    this.usernameSaved$ = new Subject();
    this.avatarSaved$ = new Subject();
    this.usernameHidden$ = new Subject();
    this.openMenu$ = new Subject();
    this.closeMenu$ = new BehaviorSubject(undefined);
    this.showLogo$ = new BehaviorSubject(true);
    this.destroyRoom$ = new Subject();

    this.join$ = new Subject();
    this.rollButtonClicked$ = new Subject();
    this.enableRollButton$ = new BehaviorSubject(false);
    this.enableCashoutButton$ = new BehaviorSubject({enabled: false});
    this.selectedToken$ = new Subject();
    this.playerActivate$ = new Subject();
    this.playAgain$ = new Subject();
    this.orderPlayersDone$ = new Subject(false);

    this.playerPreferences$ = new BehaviorSubject({
      supplier_user: '',
      username: '',
      hide_username: false,
      avatar: undefined,
      sound: true,
      music: true,
    });

    this.gameIsStarted$ = new BehaviorSubject(false);
    this.leaveGame$ = new Subject();
    this.leaveGameAfterCrush$ = new Subject();
    this.gameRoomStarted$ = new BehaviorSubject(false);

    this.serverGameStarted$ = new BehaviorSubject(false);
    this.serverUpdateBalance$ = new Subject();
    this.serverGameEnded$ = new BehaviorSubject(false);
    this.serverError$ = new Subject();
    this.cashout$ = new BehaviorSubject({value: 0});

    this.reconnecting$ = new BehaviorSubject(false);
    this.sessionLost$ = new BehaviorSubject(false);
    this.notEnoughMoney$ = new BehaviorSubject(false);
    /**
     * @type {BehaviorSubject<boolean | {close: () => void}>}
     */
    this.insufficientFunds$ = new BehaviorSubject(false);
    this.criticalModal$ = new BehaviorSubject(false);
    this.balanceErrorModal$ = new BehaviorSubject(false);
    this.maintenanceModal$ = new BehaviorSubject(false);
    this.cashoutInfoModal$ = new BehaviorSubject(false);
    this.automoveModal$ = new BehaviorSubject(false);
    this.sendOfferToGiveUpModalSm$ = new BehaviorSubject(false);
    this.sendOfferToGiveUpModalLg$ = new BehaviorSubject(false);
    this.getOfferToGiveUpModal$ = new BehaviorSubject(false);
    this.currecyNotSupportedModal$ = new BehaviorSubject(false);
    this.hideAllModals$ = new BehaviorSubject(false);
    /**
     * @type {BehaviorSubject<boolean | {exit: () => void, close: () => void}>}
     */
    this.leaveGameModal$ = new BehaviorSubject(false);
    this.cashoutModal$ = new BehaviorSubject(false);
    this.restoreModal$ = new BehaviorSubject(false);
    this.unrestoringRoundModal$ = new BehaviorSubject(false);

    this.startWarningSkipValue$ = new BehaviorSubject(
      window.localStorage?.getItem('skipStartWarning')
    );
    /**
     * @type {BehaviorSubject<boolean | {start: () => void, close: () => void}>}
     */
    this.startWarning$ = new BehaviorSubject(false);
    this.monitoringPlayerTokenMove$ = new BehaviorSubject(false);
    // this.startWarningClose$ = new Subject();

    this.activePlayerTeam$ = new BehaviorSubject(PlayersPositions.BL);

    this.updateForce$ = new BehaviorSubject(false);
    this.dices$ = {
      [PlayersPositions.TL]: new BehaviorSubject({
        diceValue: 0,
        diceAnimate: false,
      }),
      [PlayersPositions.TR]: new BehaviorSubject({
        diceValue: 0,
        diceAnimate: false,
      }),
      [PlayersPositions.BR]: new BehaviorSubject({
        diceValue: 0,
        diceAnimate: false,
      }),
      [PlayersPositions.BL]: new BehaviorSubject({
        diceValue: 0,
        diceAnimate: false,
      }),
    };

    this.playerColors$ = {
      [PlayersPositions.TL]: new BehaviorSubject(undefined),
      [PlayersPositions.TR]: new BehaviorSubject(undefined),
      [PlayersPositions.BR]: new BehaviorSubject(undefined),
      [PlayersPositions.BL]: new BehaviorSubject(undefined),
    };

    this.playerUsernames$ = {
      [PlayersPositions.TL]: new BehaviorSubject(undefined),
      [PlayersPositions.TR]: new BehaviorSubject(undefined),
      [PlayersPositions.BR]: new BehaviorSubject(undefined),
      [PlayersPositions.BL]: new BehaviorSubject(undefined),
    };

    this.playerAvatars$ = {
      [PlayersPositions.TL]: new BehaviorSubject(0),
      [PlayersPositions.TR]: new BehaviorSubject(0),
      [PlayersPositions.BR]: new BehaviorSubject(0),
      [PlayersPositions.BL]: new BehaviorSubject(0),
    };

    this.tokens$ = {
      [PlayersPositions.TL]: [
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
      ],
      [PlayersPositions.TR]: [
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
      ],
      [PlayersPositions.BR]: [
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
      ],
      [PlayersPositions.BL]: [
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
        new BehaviorSubject({ position: -1, prevPosition: -1, killed: false }),
      ],
    };

    this.tokenHints$ =  [
        new BehaviorSubject({}),
        new BehaviorSubject({}),
        new BehaviorSubject({}),
        new BehaviorSubject({}),
      ];

    this.tokensScale$ = {
      [PlayersPositions.TL]: [
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
      ],
      [PlayersPositions.TR]: [
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
      ],
      [PlayersPositions.BR]: [
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
      ],
      [PlayersPositions.BL]: [
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
        new BehaviorSubject({ length: 1, position: 0 }),
      ],
    };

    this.blocksVisible$ = {
      [PlayersPositions.TL]: new BehaviorSubject(false),
      [PlayersPositions.TR]: new BehaviorSubject(false),
      [PlayersPositions.BR]: new BehaviorSubject(false),
      [PlayersPositions.BL]: new BehaviorSubject(false),
    };

    this.availableTokens$ = {
      [PlayersPositions.TL]: new BehaviorSubject([]),
      [PlayersPositions.TR]: new BehaviorSubject([]),
      [PlayersPositions.BR]: new BehaviorSubject([]),
      [PlayersPositions.BL]: new BehaviorSubject([]),
    };

    this.absolutePositions$ = new Subject([]);

    this.timerPlayerMulti$ = new BehaviorSubject({ available: false, time: 0 });
    this.timerCashoutButton$ = new BehaviorSubject({ time: 0 });
    this.playerLeftGame$ = new Subject();
    this.autoPlayEnabled$ = new BehaviorSubject(false);

    this.incomesSurrenderPropositions$ = new BehaviorSubject([]);
    this.surrenderPropositionCount$ = new BehaviorSubject(3);
    this.sendedSurrenderPropositions$ = new BehaviorSubject([]);
    this.gameTypes$ = new Subject();
    this.gameModes$ = new Subject();
    this.availablePlayers$ = new Subject();
    this.tokenMove$ = new Subject();
    this.updateHintsSettings$ = new Subject();
  }

  get isQuickMode() {
    return this.selectedMode$.getValue() === 'quick';
  }

  set skipStartMessage(value) {
    window.localStorage?.setItem('skipStartWarning', value ? 'true' : 'false');
    this.startWarningSkipValue$.next(
      window.localStorage?.getItem('skipStartWarning')
    );
  }

  get skipStartMessage() {
    return this.startWarningSkipValue$.value === 'true';
  }

  clear(replay = false) {
    const isQuick = this.selectedMode$.getValue() === 'quick';

    this.updateForce$.next(true);
    const colors = [
      PlayersPositions.TL,
      PlayersPositions.TR,
      PlayersPositions.BR,
      PlayersPositions.BL,
    ];

    for (let i = 0; i < colors.length; i++) {
      this.dices$[colors[i]].next({ diceValue: 0, diceAnimate: false });
      this.availableTokens$[colors[i]].next([]);
      this.blocksVisible$[colors[i]].next(false);

      if (!(replay && this.mode$.value === 'single')) {
        this.playerColors$[colors[i]].next(undefined);
      }

      for (let y = 0; y < 4; y++) {
        if (isQuick) {
          const position = y < 2 ? 0 : -1;
          this.tokens$[colors[i]][y].next({
            position: position,
            prevPosition: position,
            killed: false,
          });
          this.tokensScale$[colors[i]][y].next({
            length: y < 2 ? 2 : 1,
            position: y < 2 ? y : 0,
          });
        } else {
          this.tokens$[colors[i]][y].next({
            position: -1,
            prevPosition: -1,
            killed: false,
          });
          this.tokensScale$[colors[i]][y].next({ length: 1, position: 0 });
        }
      }
    }

    interval(500)
      .pipe(take(1))
      .subscribe((_) => this.updateForce$.next(false));
  }
}
