import i18next from 'i18next';
import * as PIXI from 'pixi.js';
import { BaseModal } from './BaseModal';
import {gsap} from "gsap";

export class AutomoveModal extends BaseModal {
  /**
   * @param rootContainer {SceneManager}
   * @param model {Model}
   * @param resizeManager {ResizeManager}
   */

  constructor(rootContainer, model, resizeManager, soundManager) {
    super(rootContainer, model, resizeManager, soundManager);

    this.blackBg.visible = false;
  }

  createModal() {
    const container = new PIXI.Container();

    const bg = container.addChild(PIXI.Sprite.from('automoveTooltip'));
    bg.pivot.set(bg.width / 2, bg.height / 2);
    bg.position.set(540, 1500);

    const text = container.addChild(new PIXI.Text(i18next.t('tooltips.times_up'), {
      fontFamily: 'Mybahnschrift',
      fontSize: '52px',
      fontWeight: 'lighter',
      lineHeight: 80,
      letterSpacing: 5,
      fill: "#2E2F73",
      align: "center",
    }));
    text.anchor.set(0.5);
    text.position.set(520, 1470);


    this.addAnimationItems([bg, text]);

    return container;
  }

  show() {
    this.visible = true;
    this.clearAnimations();

    for(let i = 0; i < this.animationObjects.length; i++) {
      const { target, width, height } = this.animationObjects[i];

      gsap.getTweensOf(target).forEach(tween => {
        tween.pause(1);
        tween.kill();
      });

      target.alpha = 0;
      target.width = width * 0.86;
      target.height = height * 0.86;

      this.appearTargets(target, width, height, !target.isNotScalable);
    }

    this.tweenTimer = gsap.delayedCall(1, this.remove.bind(this));
  }

  async remove() {
    await this.hide();
    this.model.automoveModal$.next(false);
  }

  hide() {
    return new Promise(resolve => {
      this.clearAnimations();

      for(let i = 0; i < this.animationObjects.length; i++) {
        const { target, width, height } = this.animationObjects[i];

        gsap.getTweensOf(target).forEach(tween => {
          tween.pause(1);
          tween.kill();
        });

        gsap.to(target, {
          alpha: 0,
          width: width * 0.86,
          height: height * 0.86,
          duration: 0.3,
          onComplete: () => {
            if (i === this.animationObjects.length - 1) {
              resolve();
            }
          }
        });
      }
    });
  }

  clearAnimations() {
    if (this.tweenTimer) {
      gsap.killTweensOf(this.tweenTimer);
    }
  }
}
