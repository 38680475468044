import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class CurrencyNotSupportedModal extends BaseModal {
  /**
   * @param rootContainer {SceneManager}
   * @param model {Model}
   * @param resizeManager {ResizeManager}
   */
  constructor(rootContainer, model, resizeManager, soundManager) {
    super(rootContainer, model, resizeManager, soundManager);
  }

  createModal() {
    const container = new PIXI.Container();
    const bg = container.addChild(PIXI.Sprite.from('noCurrencyModal'));
    bg.pivot.set(bg.width / 2, bg.height / 2);
    bg.position.set(541, 857);

    const text = container.addChild(new PIXI.Text(i18next.t('modals.currency_is_not_supported'), {
      fontFamily: "Akshar-Medium",
      fontSize: "64px",
      fill: "#ffdb15",
      align: "center",
      stroke: "#490000",
      strokeThickness: 10,
      wordWrapWidth: 900,
      wordWrap: true,
    }));
    text.anchor.set(0.5);
    text.position.set(545, 1010);

    this.btn = this.createBtn(container, 'modals.exit', () => this.onExit());
    this.btn.position.set(this.width / 2, 1246);

    this.addAnimationItems([bg, text, this.btn]);

    return container;
  }

  async onExit() {
    this.btn.disabled = true;

    await this.hide();
    this.model.clear();
    this.model.leaveGameAfterCrush$.next(undefined);

    // set in url lobby url
    const lobbyUrl = this.model.gdh.urlParams.get('lobby_url');
    if (lobbyUrl) {
      window.location.href = lobbyUrl;
    }
  }

  show() {
    super.show();
    this.btn.disabled = false;
  }
}
