import * as PIXI from "pixi.js";
import i18next from 'i18next';
import { gsap } from "gsap";
import {BaseModal} from "./BaseModal";
import {EnumTopType, TopContainer} from "../objects/TopContainer";
import {SpineHelper} from "../helpers/SpineHelper";
import {PlayersPositions} from "../data/GameProperties";

export class CashoutModal extends BaseModal {
    constructor(rootContainer, model, resizeManager, soundManager, server) {
        super(rootContainer, model, resizeManager, soundManager);

        this.server = server;
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('cashoutModal'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 915);

        this.text = container.addChild(new PIXI.Text(`${i18next.t('modals.want_to_cash_out')}`, {
            fontFamily: "Akshar-Medium",
            fontSize: "60px",
            fill: ["#FFDB15", "#FFA800"],
            align: "center",
            stroke: "#490000",
            strokeThickness: 13,
            wordWrapWidth: 900,
            wordWrap: true,
            dropShadow: true,
            dropShadowColor: "#000000",
            dropShadowDistance: 5,
            dropShadowAngle: 0.5
        }));
        this.text.anchor.set(0.5);
        this.text.position.set(545, 1081);

        this.confirmButton = this.createBtn(container, 'modals.cashout', () => this.onExit());
        this.confirmButton.position.set(291, 1317);

        this.cancelButton = this.createBtn(container, 'modals.no', () => this.onCancel());
        this.cancelButton.position.set(754, 1317);
        this.addAnimationItems([bg, this.text, this.confirmButton, this.cancelButton]);
        this.isGettingCashback = false;
        this.isShow = false;

        this.model.tokenMove$
            .subscribe(player => {
                if (this.isShow && player.team === PlayersPositions.BL && !this.isGettingCashback) {
                    this.onCancel();
                }
            });

        this.topContainer = container.addChild(
            new TopContainer(
                EnumTopType.WIN,
                this.model,
                this.soundManager,
                this.server
            )
        );

        this.winPanel = container.addChild(new SpineHelper('winPanel'));
        this.winPanel.position.set(540, 85);
        this.winPanel.visible = false;

        return container;
    }

    async onExit() {
        this.confirmButton.disabled = true;
        this.cancelButton.disabled = true;
        this.isGettingCashback = true;

        this.winPanelAnimation();
        await this.topContainer.updateBalance(this.cashout, true);
        await new Promise(r => setTimeout(r, 1000));

        await this.hide();

        const value = this.model.cashoutModal$.getValue();
        if (value && typeof value.exit === 'function') {
            value.exit();
        }

        this.model.serverGameStarted$.next(false);
        this.model.gameIsStarted$.next(false);
        this.model.clear();
        this.model.leaveGame$.next({isLeaved: true, isCashout: true});
        this.model.cashoutModal$.next(false);

        if (this.model.mode$.value === 'multi') {
            await new Promise(resolve => {
                gsap.delayedCall(1, resolve);
            });

            await this.server.join();
        }
    }

    async onCancel() {
        this.cancelButton.disabled = true;
        await this.hide();

        const value = this.model.cashoutModal$.getValue();
        if (value && typeof value.close === 'function') {
            value.close();
        }

        this.model.gameIsStarted$.next(true);
        this.model.cashoutModal$.next(false);
    }

    show() {
        super.show();
        this.isShow = true;
        this.confirmButton.disabled = false;
        this.cancelButton.disabled = false;
        this.cashout = this.model.gdh.cashout;
        this.text.text = `${i18next.t('modals.want_to_cash_out')} ${this.model.gdh.formatCurrency(this.cashout)}`;
    }

    winPanelAnimation() {
        this.winPanel.visible = true;
        this.winPanel.skeleton.setSkinByName('default');
        this.winPanel.setAnimation('animation');

        const duration = this.winPanel.animations['animation'];

        this.winPanel.tween = gsap.fromTo(this.winPanel, { time: 0 }, {
            time: duration,
            duration: duration,
            repeat: 0,
            onComplete: () => {
                gsap.getTweensOf(this.winPanel).forEach((tween) => {
                    tween.pause(1);
                    tween.kill();
                    this.winPanel.visible = false;
                });
            }
        });
    }

    hide() {
        super.hide();
        this.isShow = false;
    }
}
