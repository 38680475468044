import {PlayersPositions} from "../../data/GameProperties";

export default {
    ROOM_NAME: "ludo-room",
    ROOM_MULTI_NAME: "ludo-multi-room",
    // ROOM_NAME: "lobby",
    Teams: ["blue", "red", "green", "yellow"],
    TeamsIndex: [PlayersPositions.TL, PlayersPositions.TR, PlayersPositions.BR, PlayersPositions.BL],
    PlayerPosition: PlayersPositions.BL,
    TeamsPositions: [PlayersPositions.BL, PlayersPositions.TL, PlayersPositions.TR, PlayersPositions.BR],
    Server: {
        TOKEN_TO_MOVE: "TOKEN_TO_MOVE",
        TOKEN_EATEN: "TOKEN_EATEN",
        DICE_ROLLING: "DICE_ROLLING",
        DICE_ENABLE: "DICE_ENABLE",
        PLAYER_AUTO_PLAY: "PLAYER_AUTO_PLAY",
        PLAYER_PLAY_AGAIN: "PLAYER_PLAY_AGAIN",
        ROOM_DISPOSING: "ROOM_DISPOSING",
        GAME_START: "GAME_START",
        GAME_END: "GAME_END",
    },
    Client: {
        DICE_ROLL: "DICE_ROLL",
        PLAYER_PLAY_AGAIN: "PLAYER_PLAY_AGAIN",
        LEAVE_GAME: "LEAVE_GAME",
        GAME_END: "GAME_END",
        __SYNC__: "__SYNC__",
        UPDATE_GAME_SETTINGS: "UPDATE_GAME_SETTINGS",
        CASH_OUT: "CASH_OUT",
    },
    Events: {
        SERVER_GAME_START: "SERVER_GAME_START",
        SERVER_GAME_END: "SERVER_GAME_END",
        SERVER_GAME_MODE: "SERVER_GAME_MODE",
        SERVER_PLAYER_ACTIVATE: "SERVER_PLAYER_ACTIVATE",
        SERVER_PLAYER_LEFT: "SERVER_PLAYER_LEFT",
        SERVER_PLAYER_WIN: "SERVER_PLAYER_WIN",
        SERVER_PLAYER_AUTO_PLAY: "SERVER_PLAYER_AUTO_PLAY",
        SERVER_PLAYER_PLAY_AGAIN: "SERVER_PLAYER_PLAY_AGAIN",
        SERVER_TOKEN_EATEN: "SERVER_TOKEN_EATEN",
        SERVER_TOKEN_SELECTED: "SERVER_TOKEN_SELECTED",
        SERVER_DICE_ROLLED: "SERVER_DICE_ROLLED",
        SERVER_DICE_ROLLING: "SERVER_DICE_ROLLING",
        SERVER_DICE_ENABLE: "SERVER_DICE_ENABLE",
        SERVER_UPDATE_BALANCE: "SERVER_UPDATE_BALANCE",
        SERVER_TIMER_START: "SERVER_TIMER_START",
        SERVER_ERROR: "SERVER_ERROR",
        GAME_START: "GAME_START",
        DICE_ROLLING: "DICE_ROLLING",
        DICE_RESET_SCALE: "DICE_RESET_SCALE",
        TOKEN_IS_SELECTED: "TOKEN_IS_SELECTED",
        TOKEN_ON_FIELD: "TOKEN_ON_FIELD",
        FIELD_UPDATED: "FIELD_UPDATED",
        TOKEN_POINTER_UP: "TOKEN_POINTER_UP",
        LOBBY_ENTER: "LOBBY_ENTER",
        LOBBY_ROOMS_LOAD: "LOBBY_ROOMS_LOAD",
        LOBBY_ROOM_UPDATE: "LOBBY_ROOM_UPDATE",
        LOBBY_ROOM_LIST: "LOBBY_ROOM_LIST",
        LEAVE_GAME: "LEAVE_GAME",
        LEAVE_GAME_AFTER_CRUSH: "LEAVE_GAME_AFTER_CRUSH",
        EXIT_GAME: "EXIT_GAME"
    },
    Values: {
        TIMER_SECONDS: 15,
        APPLICATION_WIDTH: 1080,
        APPLICATION_HEIGHT: 1920,
        DEFAULT_CURRENCY: "XXX",
        DEFAULT_BETS: [100],
        Teams: {
            [PlayersPositions.TL]: {
                START_FIELD: "0",
                FINISH_FIELD: "50",
                TEXTURE: "token1",
            },
            [PlayersPositions.TR]: {
                START_FIELD: "13",
                FINISH_FIELD: "11",
                TEXTURE: "token2",
            },
            [PlayersPositions.BR]: {
                START_FIELD: "26",
                FINISH_FIELD: "24",
                TEXTURE: "token3",
            },
            [PlayersPositions.BL]: {
                START_FIELD: "39",
                FINISH_FIELD: "37",
                TEXTURE: "token4",
            },
        },
    },
    Errors: {
        JOIN: {
            type: "join",
            message: "There was an error with joining room\nplease try again later.",
        },
        ROOM_DISPOSING: {
            type: "roomDisposing",
            message: "There was an error with server\nplease try again later.",
        },
    },
    Messages: {
        PLAYERS_WAITING: "Waiting for other players to accept...",
        PLAYERS_LEFT: "Sorry, other players left the room.",
        PLAY_AGAIN_1: "Player from ",
        PLAY_AGAIN_2: " team wants to play again.",
    },
    GameType: {
        SINGLEPLAYER: "SINGLEPLAYER",
        MULTIPLAYER: "MULTIPLAYER",
    },
    GameState: {
        START: 'START',
        ROLL_TO_PLAY_FIRST: 'ROLL_TO_PLAY_FIRST',
        GAME: 'GAME'
    },
    Cookies: {
        COOKIE_HINTS_VISIBLE: 'COOKIE_HINTS_VISIBLE',
        COOKIE_CASHOUT_TOOLTIP: 'COOKIE_CASHOUT_TOOLTIP'
    }
};
