import * as PIXI from "pixi.js";
import gsap from "gsap";
import * as particles from '@pixi/particle-emitter';
import {filter} from "rxjs";
import { SpineHelper } from "../helpers/SpineHelper";
import { BaseModal } from "./BaseModal";
import * as particleSettings from '../assets/particles/emitter.json';
import { EnumGameModes } from "../helpers/constants/GameModes";
import {EnumTopType, TopContainer} from "../objects/TopContainer";

const particlesList = [
    '../assets/particles/star_ludo.png',
    '../assets/particles/rubin1.png',
    '../assets/particles/kispar1.png',
];

const particlesConfigs = [
    {
        x: 700,
        y: 500,
    },
    {
        x: 300,
        y: 1500,
    },
    {
        x: 250,
        y: 550,
    },
    {
        x: 800,
        y: 1200,
    },
    {
        x: 300,
        y: 1100,
    },
    {
        x: 800,
        y: 400,
    }
];

export class WinModal extends BaseModal {
    constructor(rootContainer, model, soundManager, resizeManager, server) {
        super(rootContainer, model, resizeManager, soundManager);

        this.soundManager = soundManager;
        this.server = server;

        this.textStyle = {
            align: "center",
            wordWrapWidth: 900,
            wordWrap: true,
            fontFamily: "Chunkfive",
            fill: '#ffea00',
            fontSize: '150px',
            stroke: '#490000',
            strokeThickness: 14,
        };
    }

    createModal() {
        this.container = new PIXI.Container();

        this.modalObj = this.container.addChild(new SpineHelper('winModal'));
        this.modalObj.pivot.set(this.modalObj.width / 2, this.modalObj.height / 2);
        this.modalObj.position.set(540, 1000);

        this.textContainer = this.container.addChild(new PIXI.Container());
        this.textContainer.width = 800;
        this.textContainer.height = 200;
        this.textContainer.position.set(537, 1059);

        this.playAgainButton = this.createBtn(this.container, 'modals.play_again', () => this.onPlayAgain(), "50px");
        this.playAgainButton.position.set(291, 1260);

        this.exitButton = this.createBtn(this.container, 'modals.exit', () => this.onExit(), "50px");
        this.exitButton.position.set(754, 1260);

        this.title = this.container.addChild(new PIXI.Text('YOU WIN', {
            fontFamily: "Chunkfive",
            fontSize: "60px",
            fill: "#E6C300",
            stroke: "#230B7D",
            strokeThickness: 9,
        }));
        this.title.anchor.set(0.5);
        this.title.position.set(540, 820);

        this.particleContainer = this.container.addChild(new PIXI.ParticleContainer());
        this.particleContainer.position.set(540, 300);
        this.particleContainer.scale.set(1.5, 1.5);

        this.topContainer = this.container.addChild(
            new TopContainer(
                EnumTopType.WIN,
                this.model,
                this.soundManager,
                this.server
            )
        );
        this.winPanel = this.container.addChild(new SpineHelper('winPanel'));
        this.winPanel.position.set(540, 85);
        this.winPanel.visible = false;

        this.initSubs();
        this.toggleElementsVisibility(false);

        return this.container;
    }

    initSubs() {
        this.model.serverGameEnded$
        .pipe(filter(e => !!e))
        .subscribe(data => {
            if (!data || !data.playerWin || this.visible) {
                return;
            }

            const win = this.model.gdh.possibleWin;
            this._createText(this.model.gdh.formatCurrency(win), this._possibleWin(win));


            this.timeline = gsap.timeline();
            this.timeline
                .add(
                    particlesConfigs.forEach(({ x, y }, i) => {
                        const delay = i * 0.15;
                        for (let j = 0; j < particlesList.length; j++) {
                            this.createParticles(x, y, delay, particlesList[j]);
                        }
                    })
                )
                .add(
                    () => {
                        this.modalObj.skeleton.setSkinByName('default');
                        this.modalObj.setAnimation('in');
                        this.winPanelTimeout = setTimeout(
                            this.winPanelAnimation.bind(this),
                            2000
                        );

                        let duration = this.modalObj.animations['in'];

                        this.modalObj.tween = gsap.fromTo(this.modalObj, { time: 0 }, {
                            time: duration,
                            duration: duration,
                            repeat: 0,
                            onComplete: () => {
                                this.modalObj.setAnimation('loop');
                                let duration = this.modalObj.animations['loop'];
                                this.modalObj.tween = gsap.fromTo(this.modalObj, {
                                    time: 0
                                },
                                {
                                    time: duration,
                                    duration: duration,
                                    repeat: -1,
                                });
                            }
                        });
                    }
                )
                .add(
                    () => {
                        this.toggleElementsVisibility(true);
                    }, 1.7
                )

        });
    }

    winPanelAnimation() {
        this.topContainer.updateBalance(this.model.gdh.possibleWin);

        this.winPanel.visible = true;
        this.winPanel.skeleton.setSkinByName('default');
        this.winPanel.setAnimation('animation');

        const duration = this.winPanel.animations['animation'];

        this.winPanel.tween = gsap.fromTo(this.winPanel, { time: 0 }, {
            time: duration,
            duration: duration,
            repeat: 0,
            onComplete: () => {
                gsap.getTweensOf(this.winPanel).forEach((tween) => {
                    tween.pause(1);
                    tween.kill();
                });
                this.winPanel.visible = false;
            }
        });
    }

    toggleElementsVisibility(value) {
        this.playAgainButton.visible = value;
        this.exitButton.visible = value;
        this.title.visible = value;
        if (this._text) this._text.visible = value;
    }

    show() {
        super.show();

        this.playAgainButton.disabled = false;
        this.exitButton.disabled = false;
    }

    async onPlayAgain() {
        clearTimeout(this.winPanelTimeout);

        this.server.updateGameSettings();
        this.modalObj.setAnimation('out');
        let duration = this.modalObj.animations['out'];
        this.toggleElementsVisibility(false);
        this.modalObj.tween = gsap.fromTo(this.modalObj,
            { time: 0 },
            {
                time: duration,
                duration: duration,
                repeat: 0,
                onComplete: () => {
                    this.hide();
                    gsap.getTweensOf(this.modalObj).forEach((tween) => {
                      tween.pause(1);
                      tween.kill();
                    });
                }
        });


        this.playAgainButton.disabled = true;
        // await this.hide();

        this.model.serverGameEnded$.next(undefined);

        gsap.delayedCall(0.32, () => {
            this.model.clear(true);

            switch (this.model.mode$.value) {
                case EnumGameModes.SINGLE:
                    this.model.playAgain$.next({toEnd: false});
                    this.model.gameRoomStarted$.next(false);
                    break;

                case EnumGameModes.MULTI:
                    (async () => {
                        this.model.playAgain$.next({toEnd: false});
                        this.model.activeMatchMaking$.next(true);
                        this.server.destroyRoom();
                        await this.server.joinToMultiRoom();
                        this.model.gameRoomStarted$.next(true);
                        this.model.serverGameStarted$.next(true);
                    })();
                    break;
            }
        });
    }

    async onExit() {
        clearTimeout(this.winPanelTimeout);

        this.modalObj.setAnimation('out');
        let duration = this.modalObj.animations['out'];
        this.toggleElementsVisibility(false);
        this.modalObj.tween = gsap.fromTo(this.modalObj,
            { time: 0 },
            {
                time: duration,
                duration: duration,
                repeat: 0,
                onComplete: () => {
                    this.hide();
                    gsap.getTweensOf(this.modalObj).forEach(tween => {
                        tween.pause(1);
                        tween.kill();
                    });
                }
        });
        this.exitButton.disabled = true;
        // await this.hide();

        this.model.serverGameStarted$.next(false);
        this.model.gameIsStarted$.next(false);
        this.model.clear();
        this.model.leaveGame$.next({isLeaved: true, isCashout: false, isWinOrLose: true});
        this.model.serverGameEnded$.next(undefined);
        this.server.exitRoom();

        if (this.model.mode$.value === 'multi') {
            await new Promise(resolve => {
                gsap.delayedCall(1, resolve);
            });

            await this.server.join();
        }
    }

    createParticles(x, y, delay, texture) {
        const container = this.container.addChild(new PIXI.ParticleContainer());
        container.position.set(x, y);
        container.scale.set(1.5, 1.5);

        let emitter = new particles.Emitter(container, particles.upgradeConfig(particleSettings, texture));
        emitter.time = 0;
        return gsap.to(emitter, {
            time: 2,
            duration: 2,
            delay,
            onUpdate: () => {
                emitter.update(emitter.time * 0.01);
            },
            onComplete: () => {
                this.container.removeChild(container);
                emitter.destroy();
                emitter = undefined;
                container.destroy();
            }
        })
    }

    /**
     * @param win {number}
     * @return {string}
     * @private
     */
    _possibleWin(win) {
            if (win > 99999999) {
                return '100px';
            } else if (win > 9999999) {
                return '110px';
            } else if (win > 999999) {
                return '125px';
            } else if (win > 99999) {
                return '140px';
            }

        return '140px';
    }

    _createText(text, fontSize) {
        if (this._text) {
            this.textContainer.removeChild(this._text);
            this._text.destroy(true);
            this._text = undefined;
        }

        this._text = this.textContainer.addChild(new PIXI.Text(text, {
            ...this.textStyle,
            fontSize
        }));
        this._text.visible = false;

        this._text.anchor.set(0.5);
        return this._text;
    }
}
