import * as PIXI from 'pixi.js';
import { gsap, Sine } from 'gsap';
import { filter, interval, merge, take } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DiceContainer } from '../objects/DiceContainer';
import { TokenImage } from '../objects/TokenImage';
import GameProperties, { PlayersPositions } from '../data/GameProperties';
import Constants from '../helpers/constants/Constants';
import { EnumTopType, TopContainer } from '../objects/TopContainer';
import { MainDiceContainer } from '../objects/MainDiceContainer';
import { EnumGameModes } from '../helpers/constants/GameModes';
import { SpriteButton } from '../objects/SpriteButton';
import { onButtonPressedAnimation } from '../helpers/buttonHelper';
import {SpineHelper} from "../helpers/SpineHelper";
import {Texture} from "pixi.js";

export class MainScene extends PIXI.Container {
  /**
   * @param server {Server}
   * @param model {Model}
   * @param soundManager {SoundManager}
   */
  constructor(server, model, soundManager) {
    super();

    this.server = server;
    this.model = model;
    this.soundManager = soundManager;

    this.blocks = new Map();
    this.names = [];
    this.dicePanels = [];
    this.panelTokens = [];

    this.gameMode$ = model.mode$;
    this.timerPlayerMulti$ = model.timerPlayerMulti$;
    this.activePlayerTeam$ = model.activePlayerTeam$;
    this.tokenMove$ = model.tokenMove$;

    this.addProfiles();
    this.addSurrenderBtn();
    this.createMainDice();
    this.setupScene();
    this.addBlocks();
    // this.addDices();
    this.addTokens();

    this.model.playAgain$.subscribe((data) => this.playAgain(data));

    this.model.serverGameStarted$
      .pipe(filter((e) => !!e && this.model.activeMatchMaking$.value === false))
      .subscribe((_) => this.serverGameStart());

    this.model.leaveGame$.subscribe((data) => this.leaveGame(data));

    this.model.leaveGameAfterCrush$.subscribe(async (_) => {
      //await this.restart();
      this.server.model.gameIsStarted$.next(false);
      //this.server.model.leaveGame$.next({ isLeaved: true, isCashout: false });
      //await this.server.reJoinAfterRoomFailure();
    });

    this.model.playerActivate$.subscribe((data) => this.playerActivate(data));
    this.model.playerLeftGame$.subscribe((playerId) =>
      this.removePlayerFromField(playerId)
    );

    this.activePlayerTeam$
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(value => {
        this.activeTeam = value;
      })

    this.gameMode$
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        if (this.bg) this.bg.destroy();

        this.bg = this.createAnimation('auto');
        this.bg.visible = false;
      })

    this.timerPlayerMulti$
      .pipe(
        distinctUntilChanged(),
        filter(_ => !this.model.activeMatchMaking$.value),
      )
      .subscribe(value => {
        if (!value.isTimerVisible && this.gameMode$.value === EnumGameModes.SINGLE && this.activeTeam !== PlayersPositions.BL) {
          this.setDisabledAnimation(this.bg);
        }

        if (value.time !== this.model.gdh.gameTimerValue || value.time === 0 || this.bg.visible) {
          return;
        }

        this.playAnimation(this.bg, this._disabled && this.activeTeam !== PlayersPositions.BL ? 'disabled' : 'auto', value);
      })

    this.tokenMove$
      .subscribe(() => {
        this.setDisabledAnimation(this.bg);
      })
  }

  addProfiles() {
    const pos = [
      { x: 45, y: 370, team: PlayersPositions.TL, key: "blue" },
      { x: 765, y: 370, team: PlayersPositions.TR, key: "red" },
      { x: 765, y: 1600, team: PlayersPositions.BR, key: "green" },
      { x: 45, y: 1600, team: PlayersPositions.BL, key: "yellow" },
    ];

    const currentPlayerProfileBg = this.addChild(
      PIXI.Sprite.from("currentPlayerProfile")
    );
    currentPlayerProfileBg.anchor.set(0, 0.5);
    currentPlayerProfileBg.position.set(24, 1600);

    this.container = this.addChild(new PIXI.Sprite(Texture.EMPTY));
    this.container.position.set(180, 1600);

    for (let i = 0; i < pos.length; i++) {
      const { x, y, team, key } = pos[i];

      const profile = this.addChild(new PIXI.Container());
      // profile.position.set(x, y - profile.height / 2);

      // const bg = profile.addChild(PIXI.Sprite.from(`${key}Profile`));
      // profile.bg = bg;
      const bg = profile.addChild(PIXI.Sprite.from("profileAvatarBg"));

      // --- show username in profile
      // const name = profile.addChild(new PIXI.Text('', {
      //     fill: "#FFD809",
      //     fontFamily: "Mybahnschrift",
      //     align: "center",
      //     stroke: '#2E2F73',
      //     strokeThickness: 2,
      //     dropShadow: true,
      //     dropShadowColor: '#000000',
      //     dropShadowDistance: 2,
      //     dropShadowAngle: 0.5
      // }));
      // name.anchor.set(0.5);
      // name.position.set(135, 145);

      // this.model.playerUsernames$[team]
      //     .subscribe(userName => {
      //         name.text = this.model.gdh.getFormattedName(userName, userName === '__hidden__');
      //     });
      // ---

      const avatar = profile.addChild(new PIXI.Container());
      avatar.scale.set(0.95);
      if (team === PlayersPositions.TR || team === PlayersPositions.BR) {
        avatar.position.set(150, 10);
      } else {
        avatar.position.set(10, 10);
      }
      const avatarBg = avatar.addChild(
        new PIXI.AnimatedSprite([
          PIXI.Texture.from('profileAvatarBgBlue'),
          PIXI.Texture.from('profileAvatarBgRed'),
          PIXI.Texture.from('profileAvatarBgGreen'),
          PIXI.Texture.from('profileAvatarBgYellow'),
        ])
      );
      avatarBg.gotoAndStop(0);
      // const avatarBg = avatar.addChild(PIXI.Sprite.from('profileAvatarBg'));
      // avatarBg.position.set(0, 0);

      const avatarImg = avatar.addChild(
        new PIXI.AnimatedSprite([
          PIXI.Texture.from('yourPrefAvatar1'),
          PIXI.Texture.from('yourPrefAvatar2'),
          PIXI.Texture.from('yourPrefAvatar3'),
          PIXI.Texture.from('yourPrefAvatar4'),
          PIXI.Texture.from('yourPrefAvatar5'),
          PIXI.Texture.from('yourPrefAvatar6'),
          PIXI.Texture.from('yourPrefAvatar0'),
        ])
      );
      avatarImg.position.set(2, 2);
      avatarImg.scale.set(0.79);
      avatarImg.gotoAndStop(avatarImg.totalFrames - 1);

      profile.position.set(x, y - profile.height / 2);

      // TODO: need refactor
      if (this.model.mode$.value === EnumGameModes.SINGLE) {
        const frame = this.model.gdh.avatarIndex;
        if (team === PlayersPositions.BL && frame !== null) {
          avatarImg.gotoAndStop(frame);
        }
      }

      this.model.avatarSaved$
        .pipe(
          filter(
            (_) =>
              this.model.mode$.value === EnumGameModes.SINGLE &&
              team === PlayersPositions.BL
          )
        )
        .subscribe((avatarId) => {
          const frame = avatarId !== null ? avatarId : avatarImg.totalFrames - 1;
          avatarImg.gotoAndStop(frame);
        });

      this.model.playerAvatars$[team].subscribe((avatarId) => {
        if (this.model.mode$.value === EnumGameModes.MULTI && avatarId) {
          avatarImg.gotoAndStop(Number(avatarId));
        } else if (team !== PlayersPositions.BL) {
          avatarImg.gotoAndStop(avatarImg.totalFrames - 1);
        }
      });

      this.model.playerColors$[team]
        .pipe(filter((color) => color))
        .subscribe((color) => {
          // --- for profiles with different bg colors
          // profile.removeChild(profile.bg);

          // const bg = PIXI.Sprite.from(`${color}Profile`);
          // profile.addChildAt(bg, 0);
          // profile.bg = bg;

          let frame = 0;
          switch (color) {
            case 'blue':
              frame = 0;
              break;
            case 'red':
              frame = 1;
              break;
            case 'green':
              frame = 2;
              break;
            case 'yellow':
              frame = 3;
              break;
          }
          avatarBg.gotoAndStop(frame);
        });

      this.names.push(profile);
    }

    // --- subscribe for changing username
    // merge(this.model.usernameSaved$, this.model.usernameHidden$)
    //     .subscribe(_ => {
    //         const player = this.names.find(e => e.team === PlayersPositions.BL);
    //         if (!player) {return; }
    //         player.text.text = this.model.gdh.getFormattedName();
    //     });
    // ---
  }

  addSurrenderBtn() {
    this.surrenderBtn = this.addChild(
      new SpriteButton({
        default: 'emptyBtnNormal',
        over: 'emptyBtnHover',
        pressed: 'emptyBtnPressed',
      })
    );
    this.surrenderBtn.pivot.set(
      this.surrenderBtn.width / 2,
      this.surrenderBtn.height / 2
    );
    this.surrenderBtn.position.set(540, 370);

    this.surrenderIcon = this.addChild(PIXI.Sprite.from('surrenderIcon'));
    this.surrenderIcon.anchor.set(0.5, 0.5);
    this.surrenderIcon.position.set(this.surrenderBtn.x, this.surrenderBtn.y);

    this.surrenderSpinner = this.addChild(PIXI.Sprite.from('surrenderSpinner'));
    this.surrenderSpinner.anchor.set(0.5, 0.5);
    this.surrenderSpinner.position.set(this.surrenderBtn.x, this.surrenderBtn.y);
    this.surrenderSpinner.visible = false;

    this.spinnerTimeline = gsap.fromTo(
      this.surrenderSpinner,
      { rotation: 0 },
      { rotation: Math.PI * 2, duration: 1, repeat: -1, ease: "none" }
    );

    this.surrenderBtn.pressed$.subscribe((_) => {
      this.soundManager.playSound('click');
      onButtonPressedAnimation(this.surrenderBtn);
    });

    this.surrenderBtn.click$.subscribe(async (_) => {
      const opponents = await this.server.getRoomPlayers();
      if (opponents.length === 1) {
        this.model.sendOfferToGiveUpModalSm$.next(true);
      } else {
        this.model.sendOfferToGiveUpModalLg$.next(true);
      }

    });

    this.model.mode$.subscribe((mode) => {
      this.surrenderBtn.visible = mode === EnumGameModes.MULTI;
      this.surrenderIcon.visible = mode === EnumGameModes.MULTI;
    });

    this.model.sendedSurrenderPropositions$.subscribe(value => {
      if (value.length > 0) {
        this.surrenderIcon.visible = false;
        this.surrenderBtn.disabled = true;
        this.surrenderSpinner.visible = true;
        this.spinnerTimeline.play();
      } else {
        this.surrenderIcon.visible = true;
        this.surrenderBtn.disabled = !this.model.surrenderPropositionCount$.value > 0;
        this.surrenderSpinner.visible = false;
        this.spinnerTimeline.pause();
      }
    });

    this.model.surrenderPropositionCount$.subscribe((value) => {
      this.surrenderBtn.disabled = !(value > 0);
    });
  }

  setupScene() {
    this.topContainer = this.addChild(
      new TopContainer(
        EnumTopType.GAME,
        this.model,
        this.soundManager,
        this.server
      )
    );

    const boardTexture = {
      blue: 0,
      red: 1,
      green: 2,
      yellow: 3,
    };

    this.boardContainer = this.addChild(new PIXI.Container());
    this.boardContainer.position.set(43, 486);
    this.boardContainer.scale.set(1.515);

    const board = this.boardContainer.addChild(
      new PIXI.AnimatedSprite([
        PIXI.Texture.from('board2'),
        PIXI.Texture.from('board3'),
        PIXI.Texture.from('board4'),
        PIXI.Texture.from('board1'),
      ])
    );
    board.gotoAndStop(0);

    this.server.model.playerColors$[PlayersPositions.BL]
      .pipe(filter((e) => !!e))
      .subscribe((color) => {
        board.gotoAndStop(boardTexture[color]);
        this.model.gdh.selectedColor = color;
      });

    // const playerBlueProfile = this.addChild(PIXI.Sprite.from("dicePanel"));
    // playerBlueProfile.scale.set(1.24);
    // playerBlueProfile.position.set(50, 244);
    // playerBlueProfile.team = PlayersPositions.TL;

    const playerBlueToken = this.createStaticColorToken(PlayersPositions.TL);
    playerBlueToken.position.set(238, 345);
    playerBlueToken.originalY = 345;

    // const playerRedProfile = this.addChild(PIXI.Sprite.from("dicePanel"));
    // playerRedProfile.scale.set(-1.24, 1.24);
    // playerRedProfile.position.set(1031, 244);
    // playerRedProfile.team = PlayersPositions.TR;

    const playerRedToken = this.createStaticColorToken(PlayersPositions.TR);
    playerRedToken.position.set(846, 348);
    playerRedToken.originalY = 348;

    // const playerYellowProfile = this.addChild(PIXI.Sprite.from("dicePanel"));
    // playerYellowProfile.scale.set(1.24);
    // playerYellowProfile.position.set(50, 1540);
    // playerYellowProfile.team = PlayersPositions.BL;

    const playerYellowToken = this.createStaticColorToken(PlayersPositions.BL);
    playerYellowToken.position.set(238, 1575);
    playerYellowToken.originalY = 1575;

    // const playerGreenProfile = this.addChild(PIXI.Sprite.from("dicePanel"));
    // playerGreenProfile.scale.set(-1.24, 1.24);
    // playerGreenProfile.position.set(1031, 1540);
    // playerGreenProfile.team = PlayersPositions.BR;

    const playerGreenToken = this.createStaticColorToken(PlayersPositions.BR);
    playerGreenToken.position.set(846, 1575);
    playerGreenToken.originalY = 1575;

    // this.dicePanels.push(...[
    //     playerBlueProfile, playerRedProfile, playerGreenProfile, playerYellowProfile
    // ]);

    this.panelTokens.push(
      ...[playerBlueToken, playerRedToken, playerGreenToken, playerYellowToken]
    );
  }

  createMainDice() {
    const dice = this.addChild(
      new MainDiceContainer(
        {
          syncServer$: this.server.syncServer$,
          model: this.server.model,
          updateForce$: this.server.model.updateForce$,
        },
        this.soundManager
      )
    );
    dice.scale.set(1.5);
    dice.position.set(540.3, 1804);
      //dice.alpha = 0.5;
  }

  /**
   * @param tokenPosition {'bottom-left' | 'top-left' | 'top-right' | 'bottom-right'}
   * @return {PIXI.AnimatedSprite}
   */
  createStaticColorToken(tokenPosition) {
    const colorsToken = {
      blue: 0,
      red: 1,
      green: 2,
      yellow: 3,
    };

    const token = this.addChild(
      new PIXI.AnimatedSprite([
        PIXI.Texture.from('token1'),
        PIXI.Texture.from('token2'),
        PIXI.Texture.from('token3'),
        PIXI.Texture.from('token4'),
      ])
    );

    token.pivot.set(token.width / 2, token.height / 2);
    token.gotoAndStop(0);
    token.team = tokenPosition;
    token.scale.set(0.49);
    token.originalWidth = token.width;
    token.originalHeight = token.height;

    this.model.playerColors$[tokenPosition]
      .pipe(filter((e) => !!e))
      .subscribe((color) => {
        token.gotoAndStop(colorsToken[color]);
      });

    return token;
  }

  /**
   * @param position {'bottom-left' | 'top-left' | 'top-right' | 'bottom-right'}
   */
  createPlayerTimer(position) {
    const container = new PIXI.Container();
    container.team = PlayersPositions.BR;

    const bg = container.addChild(PIXI.Sprite.from('diceTimer'));
    bg.anchor.set(0.5, 0.5);

    const time = container.addChild(
      new PIXI.Text('15', {
        fontFamily: 'Mybahnschrift',
        fontSize: '43px',
        fontWeight: 'bold',
        fill: '#FEF109',
        align: 'center',
      })
    );
    time.anchor.set(0.5, 0.5);

    this.model.activePlayerTeam$
      .pipe(filter((e) => e !== position))
      .subscribe((_) => {
        time.visible = false;
      });

    merge(this.model.activePlayerTeam$, this.model.timerPlayerMulti$)
      .pipe(
        filter(
          (_) =>
            this.model.mode$.value === 'multi' &&
            this.model.activePlayerTeam$.value === position
        )
      )
      .subscribe((_) => {
        const data = this.model.timerPlayerMulti$.value;

        time.visible =
          data.available &&
          this.model.gdh.currentGameState === Constants.GameState.GAME;
        time.text = String(data.time);
      });

    return container;
  }

  stopPanelTokenAnimation(token) {
    gsap.getTweensOf(token).forEach((tween) => {
      tween.pause(1);
      tween.kill();
    });

    gsap.to(token, {
      width: token.originalWidth,
      height: token.originalHeight,
      duration: 0.3,
      ease: 'Back.easeIn',
      y: token.originalY,
    });
  }

  animatePanelTokenSelected(token) {
    if (typeof token.team !== 'string') {
      console.error("token does not contains param 'team'");
      return;
    }

    gsap.fromTo(
      token,
      {
        height: token.originalHeight * 0.8,
      },
      {
        height: token.originalHeight + 10,
        duration: 0.6,
        delay: 0.4,
        yoyo: true,
        repeat: -1,
        ease: Sine.easeInOut,
      }
    );

    gsap.fromTo(
      token,
      {
        y: token.originalY,
      },
      {
        y: token.originalY - 30,
        duration: 0.6,
        delay: 0.4,
        repeat: -1,
        yoyo: true,
        ease: Sine.easeOut,
      }
    );

    gsap
      .timeline({ yoyo: true, repeat: -1 })
      .to(token, {
        width: -token.originalWidth * 1.1,
        duration: 0.3,
      })
      .to(token, {
        width: token.originalWidth * 1.1,
        duration: 0.3,
      });
  }

  addBlocks() {
    for (let i = 0; i < GameProperties.blocks.length; i++) {
      const { x, y, team } = GameProperties.blocks[i];
      const block = this.boardContainer.addChild(
        PIXI.Sprite.from('blockerMove')
      );
      block.scale.set(0.3);
      block.pivot.set(block.width / 2, block.height / 2);
      block.position.set(x, y);
      this.blocks.set(team, block);
      this.server.model.blocksVisible$[team]
        .pipe(distinctUntilChanged())
        .subscribe((visible) => (block.visible = !!visible));
    }
  }

  addDices() {
    const diceTL = this.addChild(
      new DiceContainer(
        {
          team: PlayersPositions.TL,
          stream$: this.server.model.dices$[PlayersPositions.TL],
          updateForce$: this.server.model.updateForce$,
          syncServer$: this.server.syncServer$,
        },
        this.soundManager
      )
    );
    diceTL.scale.set(1.12);
    diceTL.position.set(116, 336);

    const diceTR = this.addChild(
      new DiceContainer(
        {
          team: PlayersPositions.TR,
          stream$: this.server.model.dices$[PlayersPositions.TR],
          updateForce$: this.server.model.updateForce$,
          syncServer$: this.server.syncServer$,
        },
        this.soundManager
      )
    );
    diceTR.scale.set(1.12);
    diceTR.position.set(965, 336);

    const diceBR = this.addChild(
      new DiceContainer(
        {
          team: PlayersPositions.BR,
          stream$: this.server.model.dices$[PlayersPositions.BR],
          updateForce$: this.server.model.updateForce$,
          syncServer$: this.server.syncServer$,
        },
        this.soundManager
      )
    );
    diceBR.scale.set(1.12);
    diceBR.position.set(965, 1632);

    const diceBL = this.addChild(
      new DiceContainer(
        {
          team: PlayersPositions.BL,
          stream$: this.server.model.dices$[PlayersPositions.BL],
          updateForce$: this.server.model.updateForce$,
          syncServer$: this.server.syncServer$,
        },
        this.soundManager
      )
    );
    diceBL.scale.set(1.12);
    diceBL.position.set(116, 1632);

    this.dices = [diceTL, diceTR, diceBR, diceBL];
  }

  addTokens() {
    this.tokensContainer = this.boardContainer.addChild(new PIXI.Container());
    this.tokens = [];

    const colors = {
      blue: 0,
      red: 1,
      green: 2,
      yellow: 3,
    };

    GameProperties.players.forEach((player) => {
      player.tokenGameObject = this.tokensContainer.addChild(
        new TokenImage(
          {
            name: player.name,
            team: player.team,
            index: player.index,
            x: player.x,
            y: player.y,
            startingField: Constants.Values.Teams[player.team].START_FIELD,
            finishField: Constants.Values.Teams[player.team].FINISH_FIELD,
            stream$: this.server.model.tokens$[player.team][player.index],
            hint$: this.server.model.tokenHints$[player.index],
            scale$: this.server.model.tokensScale$[player.team][player.index],
            availableTokens$: this.server.model.availableTokens$[player.team],
            updateForce$: this.server.model.updateForce$,
            syncServer$: this.server.syncServer$,
            isQuickMode: () => {
              return this.server.model.isQuickMode;
            },
            onTop: () => {
              this.tokensContainer.setChildIndex(
                player.tokenGameObject,
                this.tokensContainer.children.length - 1
              );
            },
            moveEnd: () => {
              this.tokensContainer.children.sort((a, b) => a.y - b.y);
            },
          },
          this.model,
          this.soundManager
        )
      );
      player.tokenGameObject.position.set(player.x, player.y);
      player.tokenGameObject.scale.set(0.3);

      this.tokens.push(player.tokenGameObject);

      this.server.model.playerColors$[player.team]
        .pipe(filter((e) => !!e))
        .subscribe((color) => {
          player.tokenGameObject.token.gotoAndStop(colors[color]);
        });
    });
  }

  serverGameStart() {
    const activeTeams = [];
    this.model.cashout$.next({ value: 0 });
    this.server.room.state.players.forEach((player) => {
      activeTeams.push(player.team);
    });

    // this.dices.forEach((dice) => {
    //     dice.visible = activeTeams.indexOf(dice.config.team) !== -1
    // });

    GameProperties.players.forEach((player, index) => {
      player.tokenGameObject.visible = activeTeams.indexOf(player.team) !== -1;
    });

    this.dicePanels.forEach((panel) => {
      panel.visible = activeTeams.indexOf(panel.team) !== -1;
    });

    this.panelTokens.forEach((pt, i) => {
      pt.visible = activeTeams.indexOf(pt.team) !== -1;
      this.names[i].visible = pt.visible;
    });

    const stake = `${this.model.gdh.formatCurrency(
      this.model.gdh.possibleWin,
      this.model.gdh.currency
    )}`;
    this.topContainer.setStake(stake);
    this.topContainer.setStakePosition(90);
  }

  // Calls when player wants to quit the game on leave game modal
  leaveGame(data) {
    interval(300)
      .pipe(take(1))
      .subscribe((_) => {
        this.restart();
        this.model.gdh.currentGameState = Constants.GameState.START;
        this.server.leaveGame(data.isCashout, data.isWinOrLose);
      });
  }

  async playAgain(data) {
    this.restart();
    this.model.gdh.currentGameState = Constants.GameState.ROLL_TO_PLAY_FIRST;
    this.server.playAgain(data.toEnd);
  }

  restart() {
    this.isFinished = false;
    this.model.gdh.isAutomaticRollActive = false;

    this.model.clear(true);
  }

  playerActivate() {
    this.panelTokens.forEach((token) => {
      this.stopPanelTokenAnimation(token);
    });

    const team = this.server.model.activePlayerTeam$.getValue();

    const currentPanelToken = this.panelTokens.find(
      (token) => token.team === team
    );
    if (currentPanelToken) {
      this.animatePanelTokenSelected(currentPanelToken);
    }

    GameProperties.currentPlayerTeam = team;
  }

  createAnimation(name) {
    this.container.removeChildren();
    const animation = this.container.addChild(new SpineHelper('timer'));
    const trackEntry = animation.state.setAnimation(0, name, true);
    trackEntry.timeScale = 0;
    animation.skeleton.setSkinByName('default');
    animation.zIndex = 1;
    return animation;
  }

  playAnimation(animation, name, value) {
    this.setDisabledAnimation(animation);

    const duration = animation.animations[name];
    animation.setAnimation(name);
    animation.visible = true;

    animation.tween = gsap.fromTo(animation,
      {
        time: 0,
      },
      {
        time: duration,
        duration: value.time + value.time / 2,
        repeat: 0,
        onComplete: this.setDisabledAnimation,
        onCompleteParams: [animation]
      })
  }

  setDisabledAnimation(animation) {
    if (animation.tween) {
      animation.tween.pause(1);
      animation.tween.kill();
      animation.tween = null;
      animation.visible = false;
    }
  }

  removePlayerFromField(team) {
    this.tokens.forEach(token => {
      if (token.config.team === team) {
        token.visible = false;
      }
    })
  }
}
