import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";
import {onButtonPressedAnimation} from "../helpers/buttonHelper";
import {SpriteButtonNineSlice} from "../objects/SpriteButtonNineSlice";

export class UnrestoringRoundModal extends BaseModal {
  constructor(rootContainer, model, resizeManager, soundManager, server) {
    super(rootContainer, model, resizeManager, soundManager);

    this.server = server;
  }

  createModal() {
    const container = new PIXI.Container();
    const bg = container.addChild(PIXI.Sprite.from('unrestoringRoundModal'));
    bg.pivot.set(bg.width / 2, bg.height / 2);
    bg.position.set(541, 1050);

    this.text = container.addChild(new PIXI.Text(`${i18next.t('modals.unrestoring_round')}`, {
      fontFamily: "Akshar-Medium",
      fontSize: "60px",
      fill: ["#ffdb15"],
      align: "center",
      stroke: "#490000",
      strokeThickness: 8,
      wordWrapWidth: 900,
      wordWrap: true,
      dropShadow: true,
      dropShadowColor: "#000000",
      dropShadowDistance: 5,
      dropShadowAngle: 0.5,
      leading: 20,
    }));
    this.text.anchor.set(0.5);
    this.text.position.set(545, 1021);

    this.attention = container.addChild(new PIXI.Text(`${i18next.t('modals.return_bet')}`, {
      fontFamily: "Akshar-Medium",
      fontSize: "50px",
      fill: ["#FFFFFF"],
      align: "center",
      leading: 20,
      wordWrapWidth: 900,
      wordWrap: true,
      stroke: "#490000",
      strokeThickness: 10,
    }));
    this.attention.anchor.set(0.5);
    this.attention.position.set(545, 1300);

    this.confirmButton = this.createBtn(container, 'modals.go_to_lobby', () => this.onConfirm());
    this.confirmButton.position.set(486, 1590);
    this.confirmButton.initWidth(460);

    this.addAnimationItems([bg, this.text, this.confirmButton, this.attention]);

    return container;
  }

  async onConfirm() {
    this.confirmButton.disabled = true;
    await this.hide();

    this.model.gameIsStarted$.next(false);
    this.model.unrestoringRoundModal$.next(false);
  }

  async show() {
    this.confirmButton.disabled = false;
    const returnBet = this.model.gdh.formatCurrency(this.model.gdh.currentBet);
    this.attention.text = `${i18next.t('modals.return_bet')}`.replace('#number#', returnBet);
    super.show();
  }

  createBtn(container, btnTextValue = '', clickFn = () => {}, fontSize = "60px") {
    const btn = container.addChild(new SpriteButtonNineSlice({
      default: 'playButton',
      over: 'playButtonHover',
      pressed: 'playButtonPressed',
    }));

    btn.pivot.set(btn.width / 2, btn.height / 2);

    btn.pressed$.subscribe(_ => {
      this.soundManager.playSound('click');
      onButtonPressedAnimation(btn);
    });
    btn.click$.subscribe(_ => clickFn());

    const btnText = btn.addChild(new PIXI.Text(i18next.t(btnTextValue).toUpperCase(), {
      fill: "#FFD809",
      fontFamily: "Mybahnschrift",
      fontSize,
      stroke: "#2E2F73",
      strokeThickness: 4,
      dropShadow: true,
      dropShadowColor: "#000000",
      dropShadowDistance: 5,
      dropShadowAngle: 0.5,
    }));
    btnText.anchor.set(0.5);
    btnText.position.set(177, 88);
    btn.initTitle(btnText);
    return btn;
  }
}
