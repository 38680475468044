import * as PIXI from "pixi.js";
import {filter} from "rxjs";
import { AvatarWithName } from "./AvatarWithName";
import {FitPixiText} from "./FitPixiText";
import gsap from "gsap";

export const EnumTopType = {
    MODE: 'MODE',
    START: 'START',
    GAME: 'GAME',
    MATCH: 'MATCH',
    WIN: 'WIN',
}

export class TopContainer extends PIXI.Container {
    /**
     * @param screen {'MODE' | 'START' | 'GAME' | 'MATCH' | 'WIN'}
     * @param model {Model}
     * @param soundManager {SoundManager}
     * @param server {Server}
     */
    constructor(screen, model, soundManager, server) {
        super();
        this.screen = screen;
        this.model = model;
        this.server = server;
        this.soundManager = soundManager;

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const fontWeight = isSafari ? "normal" : "800";

        /**
         * @type {PIXI.ITextStyle}
         */

        this.textStyle = {
            fill: "#FFD809",
            fontFamily: 'Mybahnschrift',
            fontSize: '58px',
            fontWeight: fontWeight,
            align: 'center'
        };

        this.setup();
        this.onJoin();

        this.model.serverGameStarted$
            .pipe(filter(e => !!e))
            .subscribe(_ => this.serverGameStart());

        this.model.serverUpdateBalance$
            .subscribe(data => this.serverUpdateBalance(data));

        this.model.selectedColor$
            .subscribe(color => this.selectColor(color));

        this.model.usernameSaved$
            .subscribe(_ => this.usernameSaved());

        this.model.avatarSaved$
            .subscribe(data => this.avatarSaved(data));

        this.model.playerPreferences$
            .subscribe(_ => {
                if (this.avatar) {
                    this.avatar.setUsername(this.model.gdh.getFormattedName());
                    this.avatar.setAvatar(this.model.gdh.avatarIndex);
                }
            });
    }

    setup() {
        this.avatar = this.addChild(new AvatarWithName(this.model, this.soundManager));
        this.avatar.position.set(540, 300);

        this.balancePanel = this.addChild(PIXI.Sprite.from('balancePanel'));
        this.balancePanel.position.set(0, 40);

        this.balance = this.addChild(new FitPixiText('', this.textStyle, 400));
        this.balance.position.set(0, 40);
        this.balance.anchor.set(0.5);

        this.coins = this.addChild(PIXI.Sprite.from('coins'));
        this.coins.position.set(-230, 40);

        this.addStake();

        switch (this.screen) {
            case "START":
                this.stakeContainer.visible = false;
                this.avatar.setColor();
                this.avatar.position.set(540, 330);
                this.balancePanel.position.set(229, 103);
                this.balance.position.set(590, 150);
                this.coins.position.set(262, 117);
                break;

            case "MODE":
                this.stakeContainer.visible = false;
                this.avatar.setColor();
                this.avatar.position.set(540, 450);
                this.balancePanel.position.set(229, 103);
                this.balance.position.set(590, 150);
                this.coins.position.set(262, 117);
                break;

            case "MATCH":
                this.stakeContainer.visible = true;
                this.setStakePosition(270);
                this.avatar.position.set(540, 420 + 140);
                this.balancePanel.position.set(229, 250);
                this.balance.position.set(590, 159 + 140);
                this.coins.position.set(262, 125 + 140);
                break;

            case "GAME":
                this.stakeContainer.visible = true;
                this.setStakePosition(90);
                this.balance.visible = false;
                this.balancePanel.position.set(230, 180);
                // this.balance.position.set(590, 88);
                // this.coins.position.set(262, 54);
                this.avatar.visible = false;
                break;

            case "WIN":
                this.stakeContainer.visible = false;
                this.avatar.visible = false;
                this.balancePanel.position.set(229, 103 - 65);
                this.balance.position.set(590, 152 - 65);
                this.coins.position.set(262, 117 - 65);

                break;
        }
    }

    addStake() {
        if (this.stake) {
            this.stake.destroy();
        }

        this.stakeContainer = this.addChild(new PIXI.Container());
        this.stakeTitle = this.stakeContainer.addChild(PIXI.Sprite.from('winIcon'));
        this.stakeTitle.anchor.set(0, 0.5);
        this.stakeTitle.scale.set(0.84);

        this.stake = this.stakeContainer.addChild(new PIXI.Text(this.model.gdh.formatCurrency(this.model.gdh.currentBet), {
            fontFamily: 'Mybahnschrift',
            fontSize: 43,
            fontWeight: 'bold',
            fill: "#FFD800",
            align: "left",
        }));
        this.stake.anchor.set(0, 0.5);

        this.stakeTitle.position.set(0, 140);
        this.stake.position.set(this.stakeTitle.width + 20, 140);
        this.setStakePosition(185);
    }

    setStakePosition(y) {
        this.stakeContainer.position.set(540 - ((this.stakeTitle.width + this.stake.width + 20) / 2), y);
    }

    setStake(stake) {
        this.stake.text = stake;
    }

    serverGameStart() {
        this.setBalance(`${this.model.gdh.formatCurrency(this.model.gdh.balance, this.model.gdh.currency)}`);
    }

    serverUpdateBalance(data) {
        if (!this.server || !this.server.room) return;

        if (data.playerId === this.server.room.sessionId)
        {
            this.model.gdh.balance = data.balance;
            this.setBalance(`${this.model.gdh.formatCurrency(this.model.gdh.balance, this.model.gdh.currency)}`);
        }
    }

    setBalance(value) {
        this.balance.text = value;
    }

    async updateBalance(added, isFake = false) {
        return new Promise((resolve) => {
            const duration = 2;
            const counter = {
                number: 0
            };

            const startCounterValue = isFake
              ? this.model.gdh.balance
              : this.model.gdh.balance - added;
            const targetCounterValue = isFake
              ? this.model.gdh.balance + added
              : this.model.gdh.balance;

            gsap.fromTo(
                counter,
                {
                    number: startCounterValue
                },
                {
                    number: targetCounterValue,
                    duration: duration,
                    onUpdate: () => {
                        this.setBalance(`${this.model.gdh.formatCurrency(counter.number, this.model.gdh.currency)}`);
                    },
                    onComplete: () => {
                        resolve();
                    }
                });
        });
    }

    onJoin() {
        this.setBalance(`${this.model.gdh.formatCurrency(this.model.gdh.balance, this.model.gdh.currency)}`);
    }

    selectColor(color) {
        if (this.avatar) this.avatar.setColor(this.screen === 'MODE' ? undefined : color);
    }

    usernameSaved() {
        if (this.avatar) this.avatar.setUsername(this.model.gdh.getFormattedName());
    }

    avatarSaved(avatarIndex) {
        if (this.avatar) this.avatar.setAvatar(avatarIndex);
    }

    destroy() {
        this.avatar.removeAllListeners();
        super.destroy();
    }
}
