import * as PIXI from "pixi.js";
import {gsap} from "gsap";
import i18next from "i18next";
import {SpriteButton} from "../objects/SpriteButton";
import {onButtonPressedAnimation} from "../helpers/buttonHelper";

export class BaseModal extends PIXI.Container {
    /**
     * @param rootContainer {SceneManager}
     * @param model {Model}
     * @param resizeManager {ResizeManager}
     */
    constructor(rootContainer, model, resizeManager, soundManager) {
        super();

        /**
         * @type {Model}
         */
        this.model = model;

        this.originWidth = 1080;
        this.originHeight = 1920;

        /**
         * @type {{target: *, width: number, height: number}[]}
         */
        this.animationObjects = [];

        this.blackBg = this.addChild(this.createBlackBg(rootContainer));
        this.modal = this.addChild(this.createModal());
        this.soundManager = soundManager;

        this.visible = false;
        this.blackBg.buttonMode = true;

        resizeManager.resize$
            .subscribe(({ shiftY, scale }) => {
                this.blackBg.y = -(shiftY / scale);
                this.blackBg.scale.y = 1 / scale;
            });
    }

    /**
     * @param objects {PIXI.Sprite[]}
     */
    addAnimationItems(objects) {
        for (let i = 0; i < objects.length; i++) {
            this.animationObjects.push({
                target: objects[i],
                width: objects[i].width,
                height: objects[i].height
            })
        }
    }

    createBlackBg(rootContainer) {
        const graphics = new PIXI.Graphics();
        graphics.beginFill(0x000000, 0.75);
        graphics.drawRect(0, 0, this.originWidth, this.originHeight);
        graphics.endFill();
        return graphics;
    }

    createModal() {
        throw new Error(`Not implemented abstract method "createModal"`);
    }

    createBtn(container, btnTextValue = '', clickFn = () => {}, fontSize = "60px") {
        const btn = container.addChild(new SpriteButton({
            default: 'playButton',
            over: 'playButtonHover',
            pressed: 'playButtonPressed',
        }));
        //btn.width = btn.width * 0.88;
        //btn.height = btn.height * 0.88;
        btn.pivot.set(btn.width / 2, btn.height / 2);

        btn.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(btn);
        });
        btn.click$.subscribe(_ => clickFn());

        const btnText = btn.addChild(new PIXI.Text(i18next.t(btnTextValue).toUpperCase(), {
            fill: "#FFD809",
            fontFamily: "Mybahnschrift",
            fontSize,
            stroke: "#2E2F73",
            strokeThickness: 4,
            dropShadow: true,
            dropShadowColor: "#000000",
            dropShadowDistance: 5,
            dropShadowAngle: 0.5,
        }));
        btnText.anchor.set(0.5);
        btnText.position.set(177, 88);

        return btn;
    }

    show() {
        this.visible = true;
        this.blackBg.eventMode = 'static';
        this.blackBg.buttonMode = true;
        this.blackBg.alpha = 0;

        gsap.getTweensOf(this.blackBg).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });

        gsap.to(this.blackBg, {
            alpha: 1,
            duration: 0.3,
        });

        for(let i = 0; i < this.animationObjects.length; i++) {
            const { target, width, height } = this.animationObjects[i];

            gsap.getTweensOf(target).forEach(tween => {
                tween.pause(1);
                tween.kill();
            });

            target.alpha = 0;
            if (!target.isNotScalable) {
                target.width = width * 0.86;
                target.height = height * 0.86;
            }
            this.appearTargets(target, width, height, !target.isNotScalable);
        }
    }

    appearTargets(target, width, height, isScalable) {
        if (isScalable) {
            gsap.to(target, {
                alpha: 1,
                width,
                height,
                duration: 0.3,
            });
        } else {
            gsap.to(target, {
                alpha: 1,
                duration: 0.3,
            });
        }
    }

    hide() {
        this.blackBg.eventMode = 'none';
        this.blackBg.buttonMode = false;
        this.blackBg.alpha = 1;

        return new Promise(resolve => {
            gsap.getTweensOf(this.blackBg).forEach(tween => {
                tween.pause(1);
                tween.kill();
            });

            gsap.to(this.blackBg, {
                alpha: 0,
                duration: 0.3,
                onComplete: () => {
                    this.visible = false;
                    resolve();
                }
            });

            for(let i = 0; i < this.animationObjects.length; i++) {
                const { target, width, height } = this.animationObjects[i];

                gsap.getTweensOf(target).forEach(tween => {
                    tween.pause(1);
                    tween.kill();
                });

                if (target.isNotScalable) {
                    gsap.to(target, {
                        alpha: 0,
                        duration: 0.3,
                    });
                } else {
                    gsap.to(target, {
                        alpha: 0,
                        width: width * 0.86,
                        height: height * 0.86,
                        duration: 0.3,
                    });
                }
            }
        });
    }
}
