import * as PIXI from "pixi.js";
import {filter} from "rxjs";
import { gsap } from "gsap";
import i18next from "i18next";
import {BaseModal} from "./BaseModal";
import { EnumGameModes } from "../helpers/constants/GameModes";


export class EndGameModal extends BaseModal {
    constructor(rootContainer, model, soundManager, resizeManager, server) {
        super(rootContainer, model, resizeManager, soundManager);

        this.soundManager = soundManager;
        this.server = server;

        this.textStyle = {
            align: "center",
            wordWrapWidth: 900,
            wordWrap: true,
            fontFamily: "Akshar-Bold",
            fill: ['#FFDB15', '#FFA800'],
            fillGradientType: 0,
            fontSize: '150px',
            stroke: '#490000',
            strokeThickness: 12,
            lineHeight: 100,
        };
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('loseModalBg'),
            PIXI.Texture.from('winModalBg'),
        ]));
        bg.gotoAndStop(0);

        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 915);

        this.textContainer = container.addChild(new PIXI.Container());
        this.textContainer.width = 800;
        this.textContainer.height = 200;
        this.textContainer.position.set(537, 1059);

        this.playAgainButton = this.createBtn(container, 'modals.play_again', () => this.onPlayAgain(), "50px");
        this.playAgainButton.position.set(291, 1317);

        this.exitButton = this.createBtn(container, 'modals.exit', () => this.onExit(), "50px");
        this.exitButton.position.set(754, 1317);

        this.addAnimationItems([bg, this.playAgainButton, this.exitButton]);

        this.model.serverGameEnded$
            .pipe(filter(e => !!e))
            .subscribe(data => {
            if (!data) return;

            if (data.playerWin) {
                this.soundManager.playSound('win');
                bg.gotoAndStop(1);

                const win = this.model.gdh.possibleWin;
                this._createText(this.model.gdh.formatCurrency(win), this._possibleWin(win));
            } else {
                this.soundManager.playSound('lose');
                bg.gotoAndStop(0);

                this._createText(i18next.t('modals.lost_message'), '64px');
            }
        });

        return container;
    }

    async onPlayAgain() {
        this.server.updateGameSettings();
        this.soundManager.playSound('click');
        this.playAgainButton.disabled = true;
        await this.hide();

        this.model.serverGameEnded$.next(undefined);

        gsap.delayedCall(0.32, () => {
            this.model.clear(true);

            switch (this.model.mode$.value) {
                case EnumGameModes.SINGLE:
                    this.model.playAgain$.next({toEnd: false});
                    this.model.gameRoomStarted$.next(false);
                    break;

                case EnumGameModes.MULTI:
                    (async () => {
                        this.model.playAgain$.next({toEnd: false});
                        this.model.activeMatchMaking$.next(true);
                        this.server.destroyRoom();
                        await this.server.joinToMultiRoom();
                        this.model.gameRoomStarted$.next(true);
                        this.model.serverGameStarted$.next(true)
                    })();
                    break;
            }
        });
    }

    async onExit() {
        this.soundManager.playSound('click');
        this.exitButton.disabled = true;
        await this.hide();

        this.model.serverGameStarted$.next(false);
        this.model.gameIsStarted$.next(false);
        this.model.clear();
        this.model.leaveGame$.next({isLeaved: true, isCashout: false, isWinOrLose: true});
        this.model.serverGameEnded$.next(undefined);
        this.server.exitRoom();

        if (this.model.mode$.value === 'multi') {
            await new Promise(resolve => {
                gsap.delayedCall(1, resolve);
            });

            await this.server.join();
        }
    }

    show() {
        super.show();

        this.playAgainButton.disabled = false;
        this.exitButton.disabled = false;
    }

    /**
     * @param win {number}
     * @return {string}
     * @private
     */
    _possibleWin(win) {
        if (win > 99999999) {
            return '100px';
        } else if (win > 9999999) {
            return '110px';
        } else if (win > 999999) {
            return '125px';
        } else if (win > 99999) {
            return '140px';
        }

        return '140px';
    }

    _createText(text, fontSize) {
        if (this._text) {
            this.textContainer.removeChild(this._text);
            this._text.destroy(true);
            this._text = undefined;
        }

        this._text = this.textContainer.addChild(new PIXI.Text(text, {
            ...this.textStyle,
            fontSize
        }));

        this._text.anchor.set(0.5);
        return this._text;
    }
}
